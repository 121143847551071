* {
  font-family: "Titillium Web", Helvetica, sans-serif;
}
a {
  text-decoration: none;
  color: black;
}
.hover-link:hover{
  color: #4cae52;
}
a:hover {
  color: #4cae52;
  text-decoration: underline;

}
.nav-item {
  padding: 1rem 0;
  text-align: center;
}
.nav-item a {
  position: relative;
  color: black;
  text-decoration: none;
}
.nav-item:hover {
  cursor: pointer;
}
.ql-editor{
  min-height:200px;
}
.rendered-content a {
  color: #4cae52;
  font-weight: bold;
  text-decoration: underline;
}

/* Style for links in hover state */
.rendered-content a:hover {
  color: #3a8a3e;
  text-decoration: none;
}

/* Ensure React Quill links are styled consistently */
.ql-editor a {
  color: #4cae52;
  font-weight: bold;
  text-decoration: underline;
}

.ql-editor a:hover {
  color: #3a8a3e;
  text-decoration: none;
}
.nav-link {
  padding-bottom: 7px;
}
.nav-link:hover {
  color: #4cae52;
}
.nav-item {
  width: 80px;
}
.nav-item > a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
.nav-item > a:before {
  content: "";
  position: absolute;
  width: 100%;
  margin-top: 1rem;
  height: 2px;
  bottom: 0;
  left: 0;
  background: #4cae52;
  visibility: hidden;
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.nav-divider {
  padding: 1rem 0.2rem;
  color: #4cae52;
}
.hero {
  background-image: url("../assets/fenix_2.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
  height: 80vh;
  align-content: center;
  justify-content: center;
}
.hero-item {
  background: rgba(63, 63, 66, 0.6);
  padding: 1.5rem 8px;
  cursor: pointer;
  font-size: 1.6rem;
  align-content: center;
  align-items: center;
  color: white;
  gap:10px;
  flex-grow: 1;
  display: flex;
  font-family: Lato, monospace;
  justify-content: center;
  text-align: center;
}
.hero-item:hover {
  background: #ebebeb;

  color: black;
}
.hero-item-bottom {
  background: rgba(63, 63, 66, 0.6);
  padding: 1.5rem 8px;
  align-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.6rem;
  color: white;
  gap:10px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  text-align: center;
}
.hero-item-bottom:hover {
  background: #ebebeb;

  color: black;
}
.footer-list-item {
  padding: 4px;
}
.link {
  color: black;
}
.link:hover {
  font-weight: bold;
  color: #4cae52;
}
/* footer social icons */
.social-network a.icoFacebook:hover {
  background-color: #3b5998;
}
.social-network a.icoTwitter:hover {
  background-color: #33ccff;
}
.social-network a.icoInstagram:hover {
  background-color: #bd3518;
}
.social-network a.icoLinkedin:hover {
  background-color: #007bb7;
}
.social-network a.icoInstagram:hover i,
.social-network a.icoFacebook:hover i,
.social-network a.icoTwitter:hover i,
.social-network a.icoInstagram:hover i,
.social-network a.icoVimeo:hover i,
.social-network a.icoLinkedin:hover i {
  color: #fff;
}
a.socialIcon:hover,
.socialHoverClass {
  color: #44bcdd;
}

.social-circle li a {
  display: inline-block;
  background-color: #d3d3d3;
  position: relative;
  margin: 0 auto 0 auto;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
}
.social-circle li i {
  margin: 0;
  line-height: 50px;
  text-align: center;
}

.social-circle li a:hover i,
.triggeredHover {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.social-circle i {
  color: #fff;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
ul.social-network {
  list-style: none;
  display: inline;
  margin-left: 0 !important;
  padding: 0;
}
ul.social-network li {
  display: inline;
  margin: 0 5px;
}
.herox iframe {
  width: 100vw;
  height: 56.25vw;
  min-height: 100vh;
  border: none;
  margin:11rem 0 0 0;
  position: absolute;
  top: 50%;
  padding:0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
  pointer-events: none;
}
a {
  text-decoration: none;
  color: black;
}
.bottom-footer-link{
  color:white;
  margin:0;
text-decoration: none;
}
.bottom-footer-link:hover {
  color: black;
  text-decoration: underline;
}
.scroll-to-top-button{
  background: #333333;
  color:white;
  text-transform: none;
  display:flex;
  justify-content: space-around;
  padding:10px 1rem;
  cursor:pointer;

}
.scroll-to-top-button:hover{
  color:#333333;
  background: white;
  text-transform: none;
}

.vcf:hover{
  color:#4cae52;
}
.ics:hover{
  color:#4cae52;
}
.contactadd:hover{
  text-transform: none;
  color:white;
  border:0;
  display:flex;
  justify-content: space-evenly;
  padding:10px 20px;
  border-radius: 5px;
  cursor:pointer;
  background: #4cae52;
}
.contactadd{
  text-transform: none;
  color: #4cae52;
  border:1px solid  #4cae52;
  display:flex;
  justify-content: space-evenly;
  padding:10px 20px;
  border-radius: 5px;
  cursor:pointer;
  background: white;
}